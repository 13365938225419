<template>
  <div ref="videoContainer">
    <video ref="video" autoplay playsinline muted loop>
      <source :src="url" type="video/mp4" />
      Uw browser ondersteunt geen video's
    </video>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: "VideoPlayer",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timeline: null,
    };
  },
  mounted() {
    this.setupEventListeners();
    this.setupTimeline();
  },
  beforeUnmount() {
    this.killTimeline();
  },
  methods: {
    setupEventListeners() {
      const video = this.$refs.video;

      video.addEventListener("loadeddata", () => {
        this.onVideoLoaded();
      });
    },
    setupTimeline() {
      this.timeline = gsap.timeline();

      this.timeline.set(this.$refs.videoContainer, { autoAlpha: 0 });
      this.timeline.set(this.$refs.videoContainer, { clipPath: "inset(15%)" });
    },
    killTimeline() {
      this.timeline.kill();
      this.timeline = null;
    },
    transitionIn() {
      this.timeline.to(
        this.$refs.videoContainer,
        {
          autoAlpha: 1,
          ease: "power2.out",
          duration: 1,
        },
        0.4
      );

      this.timeline.to(
        this.$refs.videoContainer,
        {
          clipPath: "inset(0%)",
          ease: "power3.out",
          duration: 1.2,
        },
        0.4
      );
    },
    onVideoLoaded() {
      this.transitionIn();
    },
  },
};
</script>
