import Vue from "vue";
import Router from "vue-router";
import HomePage from "./pages/HomePage";
import QuizPage from "./pages/QuizPage";
import ResultsPage from "./pages/ResultsPage";
import NotFound from "./pages/NotFound";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: HomePage,
    },
    {
      path: "/quiz",
      component: QuizPage,
    },
    {
      path: "/results",
      name: "results",
      component: ResultsPage,
      props: true,
    },
    { path: "/404", component: NotFound },
    { path: "*", component: NotFound },
  ],
});

export default router;
