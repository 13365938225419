<template>
  <span ref="text">{{ text }}</span>
</template>

<script>
import { gsap } from "gsap";
import SplitType from "split-type";

export default {
  name: "AnimatedText",
  props: {
    text: {
      type: String,
      required: true,
    },
    delay: {
      type: String,
      required: false,
      default: "0",
    },
  },
  mounted() {
    this.setupTimeline();
  },
  beforeUnmount() {
    this.killTimeline();
  },
  methods: {
    setupTimeline() {
      this.timeline = gsap.timeline();

      const text = new SplitType(this.$refs.text, {
        types: "words",
        tagName: "span",
      });

      this.timeline.fromTo(
        text.words,
        { x: -40, autoAlpha: 0, skewX: -10 },
        {
          x: 0,
          autoAlpha: 1,
          skewX: 0,
          ease: "power2.out",
          duration: 1,
          stagger: { amount: 0.2 },
          onComplete: () => text.revert(),
        },
        this.$props.delay
      );
    },
    killTimeline() {
      this.timeline.kill();
      this.timeline = null;
    },
  },
};
</script>
