<template>
  <section class="homepage container">
    <img :src="t.home.logo" alt="" class="homepage__logo" />

    <div class="homepage__content">
      <h1 class="homepage__title title">
        <animated-text :text="t.home.title"></animated-text>
      </h1>
      <p class="homepage__text">
        <animated-text :text="t.home.body" delay="0.3"></animated-text>
      </p>

      <animated-button
        as="link"
        :to="t.home.cta.url"
        :text="t.home.cta.label"
        delay="1"
      ></animated-button>
    </div>

    <div class="homepage__video">
      <video-player :url="t.home.videoBackground.url"></video-player>
    </div>
  </section>
</template>

<script>
  import VideoPlayer from "@/components/VideoPlayer.vue";
  import AnimatedText from "@/components/AnimatedText.vue";
  import AnimatedButton from "@/components/AnimatedButton.vue";

  export default {
    name: "HomePage",
    components: {
      VideoPlayer,
      AnimatedText,
      AnimatedButton
    },
    props: {
      t: {
        type: Object,
        required: true
      }
    }
  };
</script>
