<template>
  <div class="share-button">
    <a class="share-button__link" :href="`${encodedUrl}`" title="Share by Email">
      <img class="share-button__icon" :src="email.icon.url" :alt="email.icon.label" />
    </a>
  </div>
</template>

<script>
  export default {
    name: "ButtonEmail",
    props: {
      email: {
        type: Object,
        required: true
      },
      shareLink: {
        type: String,
        required: true
      }
    },
    computed: {
      encodedUrl() {
        const emailString = `mailto:?subject=${this.$props.email.subject}&body=${this.$props.email.body} ${this.$props.shareLink}`;
        return encodeURI(emailString);
      }
    }
  };
</script>
