<template>
  <div class="feedback-block">
    <span class="feedback-block__feedback">
      <animated-text :text="feedback"></animated-text>
    </span>
    <p class="feedback-block__explanation">
      <animated-text :text="item.explanation" delay="0.1"></animated-text>
    </p>

    <animated-button
      as="btn"
      :text="buttons.next.label"
      @onButtonClickHandler="onButtonClickHandler"
    ></animated-button>
  </div>
</template>

<script>
import AnimatedText from "@/components/AnimatedText.vue";
import AnimatedButton from "@/components/AnimatedButton.vue";

export default {
  name: "FeedbackBlock",
  components: { AnimatedText, AnimatedButton },
  props: {
    item: {
      type: Object,
      required: true,
    },
    feedback: {
      type: String,
      required: true,
    },
    buttons: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onButtonClickHandler(value) {
      this.$emit("onButtonClick", value);
    },
  },
};
</script>
