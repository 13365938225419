<template>
  <section ref="container" class="quiz">
    <div class="quiz__video">
      <video-player
        :key="currentStep"
        :url="quizItems[currentStep].video.url"
      ></video-player>
    </div>

    <div class="quiz__content">
      <quiz-counter
        :text="t.quiz.counter.text"
        :currentStep="currentStep"
        :limit="quizItems.length"
      ></quiz-counter>
      <question-block
        v-if="!answers[currentStep]"
        :item="quizItems[currentStep]"
        @onButtonClick="onAnswerClickHandler"
      ></question-block>
      <feedback-block
        v-if="answers[currentStep]"
        :item="quizItems[currentStep]"
        :feedback="feedback"
        :buttons="t.quiz.buttons"
        @onButtonClick="onNextClickHandler"
      ></feedback-block>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import colors from "../constants/colors";
import QuizCounter from "../components/QuizCounter.vue";
import QuestionBlock from "../components/QuestionBlock.vue";
import FeedbackBlock from "../components/FeedbackBlock.vue";
import VideoPlayer from "../components/VideoPlayer.vue";

export default {
  name: "QuizPage",
  components: {
    QuizCounter,
    QuestionBlock,
    FeedbackBlock,
    VideoPlayer,
  },
  props: {
    t: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentStep: 0,
      quizItems: this.$props.t.quiz.items,
      answers: [],
      correctAnswers: 0,
      feedback: null,
      timeline: null,
    };
  },
  mounted() {
    this.setupTimeline();
  },
  beforeUnmount() {
    this.killTimeline();
  },
  methods: {
    setupTimeline() {
      this.timeline = gsap.timeline();
    },
    killTimeline() {
      this.timeline.kill();
      this.timeline = null;
    },
    saveAnswer(value) {
      const answer = {
        questionId: this.currentStep,
        answer: value,
      };

      this.answers.push(answer);
    },
    userInputIsCorrectAnswer(userInput) {
      const currentQuizItem = this.quizItems[this.currentStep];
      const correctAnswerIndex = currentQuizItem.correctAnswer;
      const correctAnswer =
        currentQuizItem.possibleAnswers[correctAnswerIndex] === userInput;

      return correctAnswer;
    },
    updateCorrectAnswers() {
      this.correctAnswers++;
    },
    showFeedback(isCorrectAnswer) {
      const backgroundColor = isCorrectAnswer ? colors.green : colors.red;
      const feedbackOptions = this.$props.t.quiz.feedback;

      this.feedback = isCorrectAnswer
        ? feedbackOptions.correct
        : feedbackOptions.wrong;

      this.updateBackgroundColor(backgroundColor);
    },
    updateBackgroundColor(backgroundColor) {
      this.timeline.to(this.$refs.container, {
        backgroundColor,
        duration: 0.3,
        ease: "Sine.easeIn",
      });
    },
    onAnswerClickHandler(userInput) {
      this.saveAnswer(userInput);

      const isCorrectAnswer = this.userInputIsCorrectAnswer(userInput);

      if (isCorrectAnswer) {
        this.updateCorrectAnswers();
      }

      this.showFeedback(isCorrectAnswer);
    },
    onNextClickHandler() {
      const limit = this.quizItems.length;
      this.currentStep++;

      if (this.currentStep === limit) {
        this.$router.push({
          name: "results",
          params: { correctAnswers: this.correctAnswers, limit },
        });
      }

      this.updateBackgroundColor(colors.yellow);
    },
  },
};
</script>
