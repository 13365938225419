<template>
  <div class="quiz-counter">
    <label class="quiz-counter__text"
      >{{ text[0] }} {{ currentStep + 1 }} {{ text[1] }} {{ limit }}</label
    >

    <div
      ref="bullet"
      v-for="(item, index) in generateBullets"
      :key="index"
      :class="item"
      class="quiz-counter__bullet"
    ></div>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: "QuizCounter",
  props: {
    text: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
  },
  computed: {
    generateBullets() {
      return Array.from(Array(this.$props.limit).keys());
    },
  },
  data() {
    return {
      timeline: null,
    };
  },
  mounted() {
    this.setupTimeline();
  },
  updated() {
    this.setupTimeline();
  },
  beforeUnmount() {
    this.killTimeline();
  },
  methods: {
    setupTimeline() {
      this.timeline = gsap.timeline();

      this.timeline.to(
        this.$refs.bullet[this.currentStep],
        {
          autoAlpha: 1,
          ease: "power2.out",
          duration: 1,
        },
        0
      );
    },
    killTimeline() {
      this.timeline.kill();
      this.timeline = null;
    },
  },
};
</script>
