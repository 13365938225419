<template>
  <div ref="button">
    <router-link v-if="asLink" :to="to" class="button" :class="as">
      <div
        class="button__inner"
        @mouseenter="onMouseEnterHandler"
        @mouseleave="onMouseLeaveHandler"
      >
        <span ref="buttonText" class="button__text" :class="`button-id-${id}`">
          {{ text }}
        </span>
        <span ref="buttonText" class="button__text" :class="`button-id-${id}`">
          {{ text }}
        </span>
        <div ref="buttonBackground" class="button__background"></div>
      </div>
    </router-link>

    <button
      v-else-if="asButton"
      :to="to"
      :value="text"
      class="button"
      :class="as"
      @click="onButtonClickHandler($event)"
      @mouseenter="onMouseEnterHandler"
      @mouseleave="onMouseLeaveHandler"
    >
      <span ref="buttonText" class="button__text" :class="`button-id-${id}`">
        {{ text }}
      </span>
      <span ref="buttonText" class="button__text" :class="`button-id-${id}`">
        {{ text }}
      </span>
      <div ref="buttonBackground" class="button__background"></div>
    </button>
  </div>
</template>

<script>
  import { gsap } from "gsap";
  import SplitType from "split-type";
  import colors from "../constants/colors";

  export default {
    name: "AnimatedButton",
    props: {
      as: {
        type: String,
        required: true,
        validator(value) {
          // The value must match one of these strings
          return ["link", "btn"].includes(value);
        }
      },
      to: {
        type: String,
        required: false
      },
      text: {
        type: String,
        required: true
      },
      delay: {
        type: String,
        required: false,
        default: "0.5"
      }
    },
    data() {
      return {
        id: null,
        buttonTimeline: null,
        buttonTextTimeline: null
      };
    },
    computed: {
      asLink() {
        return this.$props.as === "link";
      },
      asButton() {
        return this.$props.as === "btn";
      }
    },
    mounted() {
      this.id = this._uid;

      this.setupTimeline();
    },
    beforeUnmount() {
      this.killTimeline();
    },
    methods: {
      setupTimeline() {
        this.buttonTimeline = gsap.timeline();

        this.buttonTimeline.fromTo(
          this.$refs.button,
          { x: -20, autoAlpha: 0, skewX: -10 },
          {
            x: 0,
            autoAlpha: 1,
            skewX: 0,
            ease: "power2.out",
            duration: 0.8,
            stagger: { amount: 0.1 }
          },
          this.$props.delay
        );
      },
      killTimeline() {
        this.buttonTimeline.kill();
        this.buttonTimeline = null;

        this.buttonTextTimeline.kill();
        this.buttonTextTimeline = null;
      },
      onButtonClickHandler(e) {
        this.$emit("onButtonClickHandler", e.target.value);
      },
      onMouseEnterHandler() {
        this.buttonTextTimeline = gsap.timeline();

        const text = new SplitType(`.button-id-${this.id}`, {
          types: "chars",
          tagName: "div"
        });

        this.buttonTextTimeline.fromTo(
          text.chars,
          { yPercent: 0 },
          {
            yPercent: -140,
            ease: "power1.inOut",
            duration: 0.4,
            stagger: { amount: 0.2 }
          },
          0
        );

        const color = this.asLink ? colors.green : colors.purple;

        this.buttonTextTimeline.fromTo(
          this.$refs.buttonBackground,
          { scaleY: 0 },
          {
            scaleY: 1,
            transformOrigin: "bottom center",
            backgroundColor: color,
            ease: "power3.inOut",
            duration: 0.6
          },
          0.1
        );
      },
      onMouseLeaveHandler() {
        this.buttonTextTimeline.reverse();
      }
    }
  };
</script>
