<template>
  <section class="not-found container">
    <div class="not-found__content">
      <h1 class="not-found__title">
        <animated-text :text="t.notFound.title"></animated-text>
      </h1>
      <p class="not-found__text">
        <animated-text :text="t.notFound.body" delay="0.3"></animated-text>
      </p>

      <animated-button
        as="link"
        :to="t.notFound.cta.url"
        :text="t.notFound.cta.label"
      ></animated-button>
    </div>

    <div class="not-found__video">
      <video-player :url="t.notFound.videoBackground.url"></video-player>
    </div>
  </section>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer.vue";
import AnimatedText from "@/components/AnimatedText.vue";
import AnimatedButton from "@/components/AnimatedButton.vue";

export default {
  name: "NotFound",
  components: {
    VideoPlayer,
    AnimatedText,
    AnimatedButton,
  },
  props: {
    t: {
      type: Object,
      required: true,
    },
  },
};
</script>
