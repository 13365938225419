<template>
  <div class="share-button">
    <a
      class="share-button__link whatsapp-mobile"
      :href="encodedMobileURL"
      data-action="share/whatsapp/share"
      name="share-whatsapp"
      rel="nofollow noopener"
      target="_blank"
    >
      <img class="share-button__icon" :src="icon.url" :alt="icon.label" />
    </a>

    <button
      class="share-button__link whatsapp-desktop"
      @click="onButtonClickHandler(encodedDesktopURL)"
    >
      <img class="share-button__icon" :src="icon.url" :alt="icon.label" />
    </button>
  </div>
</template>

<script>
  export default {
    name: "ButtonWhatsapp",
    props: {
      shareLink: {
        type: String,
        required: true
      },
      shareMessage: {
        type: String,
        required: true
      },
      icon: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        mobileRootURL: "whatsapp://send?//",
        desktopRootURL: "whatsapp://send?"
      };
    },
    computed: {
      encodedMobileURL() {
        const shareURL = new URL(this.mobileRootURL);
        return this.encodeURL(shareURL);
      },
      encodedDesktopURL() {
        const shareURL = new URL(this.desktopRootURL);
        return this.encodeURL(shareURL);
      }
    },
    methods: {
      openInNewTab(url) {
        window.open(url);
      },
      onButtonClickHandler(url) {
        this.openInNewTab(url);
      },
      encodeURL(url) {
        url.searchParams.append("text", `${this.$props.shareMessage} ${this.$props.shareLink}`);
        return url.toString();
      }
    }
  };
</script>
