<template>
  <div class="share-button">
    <a
      class="share-button__link"
      :href="`${rootUrl}&t=${encodedUrl}`"
      name="share-facebook"
      rel="nofollow noopener"
      target="_blank"
    >
      <img class="share-button__icon" :src="icon.url" :alt="icon.label" />
    </a>
  </div>
</template>

<script>
  export default {
    name: "ButtonFacebook",
    props: {
      shareLink: {
        type: String,
        required: true
      },
      shareMessage: {
        type: String,
        required: true
      },
      icon: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        rootUrl: `https://www.facebook.com/sharer.php?`
      };
    },
    computed: {
      encodedUrl() {
        const paramsObj = {
          display: "page",
          u: this.$props.shareLink,
          t: this.$props.shareMessage
        };

        const searchParams = new URLSearchParams(paramsObj);
        return searchParams.toString();
      }
    }
  };
</script>
