<template>
  <main id="app">
    <RouterView :t="locales" />
  </main>
</template>

<script>
import { getLocales } from "./locales";

export default {
  name: "LekkerOpWegQuiz",
  data() {
    return {
      locales: getLocales(),
    };
  },
};
</script>
