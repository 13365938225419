<template>
  <section class="results container">
    <div class="results__content">
      <h1 class="results__title title">
        <animated-text
          :text="`${t.results.title[0]} ${correctAnswers} ${t.results.title[1]} ${limit} ${t.results.title[2]}`"
        ></animated-text>
      </h1>

      <p class="results__text">
        <animated-text :text="t.results.text" delay="0.3"></animated-text>
      </p>

      <socials-list :t="t"></socials-list>
    </div>
    <div class="results__video">
      <video-player :url="t.results.videoBackground.url"></video-player>
    </div>
  </section>
</template>

<script>
  import VideoPlayer from "../components/VideoPlayer.vue";
  import SocialsList from "../components/SocialsList.vue";
  import AnimatedText from "../components/AnimatedText.vue";

  export default {
    name: "ResultsPage",
    components: { VideoPlayer, SocialsList, AnimatedText },
    props: {
      t: {
        type: Object,
        required: true
      },
      correctAnswers: {
        type: Number,
        required: true
      },
      limit: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        timeline: null
      };
    },
    created() {
      if (this.$props.correctAnswers === undefined || this.$props.limit === undefined) {
        this.$router.push({
          path: "/404"
        });
      }
    }
  };
</script>
