<template>
  <section class="socials-list">
    <h2>
      <animated-text :text="t.results.socials.title" delay="0.5"></animated-text>
    </h2>
    <ul class="socials-list__list">
      <li class="socials-list__list-item">
        <button-facebook
          :shareLink="t.results.socials.shareLink"
          :shareMessage="t.results.socials.shareButtons['facebook'].body"
          :icon="t.results.socials.shareButtons['facebook'].icon"
        ></button-facebook>
      </li>
      <li class="socials-list__list-item">
        <button-whatsapp
          :shareLink="t.results.socials.shareLink"
          :shareMessage="t.results.socials.shareButtons['whatsapp'].body"
          :icon="t.results.socials.shareButtons['whatsapp'].icon"
        ></button-whatsapp>
      </li>
      <li class="socials-list__list-item">
        <button-email
          :email="t.results.socials.shareButtons['email']"
          :shareLink="t.results.socials.shareLink"
        ></button-email>
      </li>
    </ul>
  </section>
</template>

<script>
  import { gsap } from "gsap";
  import ButtonFacebook from "./shareButtons/ButtonFacebook.vue";
  import ButtonWhatsapp from "./shareButtons/ButtonWhatsapp.vue";
  import ButtonEmail from "./shareButtons/ButtonEmail.vue";
  import AnimatedText from "../components/AnimatedText.vue";

  export default {
    name: "SocialsList",
    components: { ButtonFacebook, ButtonWhatsapp, ButtonEmail, AnimatedText },
    props: {
      t: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        timeline: null
      };
    },
    mounted() {
      this.setupTimeline();
    },
    beforeUnmount() {
      this.killTimeline();
    },
    methods: {
      setupTimeline() {
        this.timeline = gsap.timeline();

        this.timeline.fromTo(
          ".socials-list__list-item",
          {
            scale: 0
          },
          {
            scale: 1,
            stagger: 0.1,
            duration: 0.5,
            ease: "power1.out"
          },
          0.8
        );
      },
      killTimeline() {
        this.timeline.kill();
        this.timeline = null;
      }
    }
  };
</script>
