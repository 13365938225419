<template>
  <div class="question-block">
    <span ref="text" class="question-block__question">
      <animated-text :text="item.question"></animated-text>
    </span>

    <ul class="question-block__answer-list">
      <li
        ref="buttons"
        v-for="(possibleAnswer, index) in item.possibleAnswers"
        :key="index"
        class="question-block__answer-list-item"
      >
        <animated-button
          as="btn"
          :text="possibleAnswer"
          @onButtonClickHandler="onButtonClickHandler"
        ></animated-button>
      </li>
    </ul>
  </div>
</template>

<script>
import { gsap } from "gsap";
import AnimatedText from "@/components/AnimatedText.vue";
import AnimatedButton from "@/components/AnimatedButton.vue";

export default {
  name: "QuestionBlock",
  components: { AnimatedText, AnimatedButton },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeline: null,
    };
  },
  mounted() {
    this.setupTimeline();
  },
  beforeUnmount() {
    this.killTimeline();
  },
  methods: {
    setupTimeline() {
      this.timeline = gsap.timeline();

      this.timeline.fromTo(
        this.$refs.buttons,
        { x: -20, autoAlpha: 0, skewX: -10 },
        {
          x: 0,
          autoAlpha: 1,
          skewX: 0,
          ease: "power2.out",
          duration: 0.8,
          stagger: { amount: 0.3 },
        },
        0.2
      );
    },
    killTimeline() {
      this.timeline.kill();
      this.timeline = null;
    },
    onButtonClickHandler(value) {
      this.$emit("onButtonClick", value);
    },
  },
};
</script>
